import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import { Timestamp } from 'firebase/firestore/lite'
import moment from 'moment-timezone'
import getConfig from 'next/config'
import { DAYJS_DAYSAGO_UPDATELOCALE } from 'src/common/Constants'
import { BuildingApi, ContactApi, UnitApi } from 'src/common/types'
import { usaStates } from 'typed-usa-states/dist/states'

import { Building, Contact, ListingFilter, Neighborhood, ReviewBuilding } from '@openigloo/common'

type currencyOptions = {
  significantDigits: number
  thousandsSeparator: string
  decimalSeparator: string
  symbol: string
}
const stateArray = usaStates.map((state) => state.abbreviation?.toLowerCase())
const companySuffixArray = ['llc', 'id', 'lp']

export const MonthsDetails = {
  Jan: {
    name: 'January',
    short: 'Jan',
    number: 1,
    days: 31,
  },
  Feb: {
    name: 'February',
    short: 'Feb',
    number: 2,
    days: 28,
  },
  Mar: {
    name: 'March',
    short: 'Mar',
    number: 3,
    days: 31,
  },
  Apr: {
    name: 'April',
    short: 'Apr',
    number: 4,
    days: 30,
  },
  May: {
    name: 'May',
    short: 'May',
    number: 5,
    days: 31,
  },
  Jun: {
    name: 'June',
    short: 'Jun',
    number: 6,
    days: 30,
  },
  Jul: {
    name: 'July',
    short: 'Jul',
    number: 7,
    days: 31,
  },
  Aug: {
    name: 'August',
    short: 'Aug',
    number: 8,
    days: 31,
  },
  Sep: {
    name: 'September',
    short: 'Sep',
    number: 9,
    days: 30,
  },
  Oct: {
    name: 'October',
    short: 'Oct',
    number: 10,
    days: 31,
  },
  Nov: {
    name: 'November',
    short: 'Nov',
    number: 11,
    days: 30,
  },
  Dec: {
    name: 'December',
    short: 'Dec',
    number: 12,
    days: 31,
  },
}

export const Months = Object.values(MonthsDetails).map((m) => m.name)

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(updateLocale)
dayjs.extend(utc)

const { publicRuntimeConfig } = getConfig()

export const removeZip = (address: string) => {
  return address?.replace(/\s\d{5}/, '')
}

export const splitAddress = (
  address: string
): { addressLine1: string; addressLine2: string; zipCode: string } => {
  const line1 = removeZip?.(address)?.split(', ')?.[0]
  const line2 = removeZip?.(address)?.split(', ').slice(1).join(', ')
  const zipCode = address?.match(/\s\d{5}/)?.[0]
  return { addressLine1: line1, addressLine2: line2, zipCode }
}
export const sentenceCase = (text: string) => {
  const start = text.charAt(0).toUpperCase()
  return start + text?.toLowerCase().substring(1)
}

export const titleCase = (text = '', splitChar = ' '): string => {
  return text
    .split(splitChar)
    .map((word) => {
      // If the word contains a special character, capitalize only the first alphabet
      if (/[^a-zA-Z0-9]/.test(word)) {
        const firstLetterMatch = word.match(/[a-zA-Z]/)

        if (firstLetterMatch) {
          const firstLetter = firstLetterMatch[0]
          const index = firstLetterMatch.index // Get the index of the first letter
          // Replace the first letter with its uppercase version
          return (
            word.slice(0, index) + firstLetter.toUpperCase() + word.slice(index + 1).toLowerCase()
          )
        }

        // If no alphabetical character is found, return the original word
        return word
      }

      // If the word contains a number, make all alphabets uppercase
      if (/\d/.test(word)) {
        return word.replace(/[a-zA-Z]/g, (char) => char.toUpperCase())
      }

      // Convert state abbreviations & company suffixes to uppercase
      if (
        stateArray.includes(word.toLowerCase()) ||
        companySuffixArray.includes(word.toLowerCase())
      ) {
        return word.toUpperCase()
      }

      // Standard title case for all other words
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join(splitChar)
}

export const baseTitleCase = (text = '', splitChar = ' '): string => {
  const stringArr = text?.toLowerCase().split(splitChar)
  for (let i = 0; i < stringArr.length; i++) {
    const str = stringArr[i]
    stringArr[i] = str.charAt(0).toUpperCase() + str.slice(1)
  }
  return stringArr.join(' ')
}

export const validateEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

export const isPastDate = (date: Date | string): boolean => {
  return new Date() >= new Date(date)
}

export const convertTimeUTC = (str) => {
  const offsetInMilliseconds = 5 * 60 * 60 * 1000
  return new Date(new Date(str).getTime() + offsetInMilliseconds)
}

export const daysAgo = (date: Date): string => {
  dayjs.updateLocale('en', DAYJS_DAYSAGO_UPDATELOCALE)
  return dayjs().to(dayjs(date), true)
}

export const isAfter = (date: Date): boolean => {
  return dayjs().isAfter(dayjs(date))
}

export const formatDate = (date: Date, format: string) => {
  return dayjs(date).format(format)
}

export const formatDateReport = (date: Date, format: string) => {
  return dayjs(date).utc().format(format)
}

export const formatTenancyDates = (date: Date): string => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const setLocalZone = (date: Date, timezone: string) => {
  const dateWithoutZone = moment.tz(date, timezone).format('YYYY-MM-DDTHH:mm:ss.SSS')
  const localZone = moment(dateWithoutZone).format('Z')
  const dateWithLocalZone = [dateWithoutZone, localZone].join('')

  return new Date(dateWithLocalZone)
}

export const currency: (value: number | string, options?: currencyOptions) => string = function (
  value,
  options = {
    significantDigits: 0,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '$',
  }
): string {
  if (typeof value === 'string') {
    if (value === '') {
      return ''
    }
    try {
      value = parseInt(value, 0)
    } catch (error) {
      value = 0
    }
  }
  const stringVal = value?.toFixed(options.significantDigits)
  const [currency, decimal] = stringVal.split('.')
  const decimalText = options.significantDigits > 0 ? `${options.decimalSeparator}${decimal}` : ''
  return `${options.symbol}${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${decimalText}`
}

export const formatCurrencyShort = (currency: number) => {
  return currency % 1000 === 0 ? `$${currency / 1000}k` : `$${(currency / 1000).toPrecision(2)}K`
}

export const paymentCurrency = (value: number | string): string => {
  return currency(value, {
    significantDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '$',
  })
}

export const monthName = (date: Date): string => {
  const month = date.getUTCMonth()

  return `${Months[month].slice(0, 3)}`
}

export const fullMonthName = (date: Date): string => {
  const month = date.getUTCMonth()

  return `${Months[month]}`
}

export const formatPublishedDate = (date: Date, noYear = false): string => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  if (noYear) {
    return `${Months[month].slice(0, 3)} ${day}`
  }
  return `${Months[month].slice(0, 3)} ${day}, ${year}`
}

export const formatShortDate = (date: Date): string => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  return `${month + 1}/${day}/${year.toString().slice(2)}`
}

export const formatPaymentDate = (date: Date): string => {
  const year = date.getFullYear().toString().slice(2)
  const month = date.getMonth()
  const day = date.getDate()

  return `${Months[month].slice(0, 3)}-${day}-${year}`
}

export const formatFullDate = (date: Date, monthOnly = false): string => {
  const year = date.getFullYear().toString()
  const month = date.getMonth()
  const day = date.getDate()

  if (monthOnly) {
    return `${Months[month]}, ${year}`
  }

  return `${Months[month]} ${day}, ${year}`
}

export const formatFullUTCDate = (date: Date, monthOnly = false): string => {
  const year = date.getUTCFullYear().toString()
  const month = date.getUTCMonth()
  const day = date.getUTCDate()

  if (monthOnly) {
    return `${Months[month]}, ${year}`
  }

  return `${Months[month]} ${day}, ${year}`
}

export const formatRent = (number: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 5,
  })
  return formatter.format(number)
}

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const val = phoneNumberString.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  return !val[2] ? val[1] : '(' + val[1] + ') ' + val[2] + (val[3] ? '-' + val[3] : '')
}

export const formatToE164 = (phoneNumber: string): string => {
  // Remove all non-numeric characters except "+"
  let cleaned = phoneNumber.replace(/[^\d+]/g, '')

  // Ensure it starts with "+1"
  if (!cleaned.startsWith('+')) {
    cleaned = '+1' + cleaned
  } else if (!cleaned.startsWith('+1')) {
    throw new Error('Invalid country code. Only US numbers (+1) are supported.')
  }

  // Extract only the last 10 digits (for US numbers)
  const match = cleaned.match(/\+1(\d{10})$/)

  if (!match) {
    throw new Error('Invalid phone number format. Must be a valid US number.')
  }

  return `+1${match[1]}` // Return in E.164 format
}

export const formatPhoneForDisplay = (phoneNumber: string): string => {
  // Remove all non-numeric characters except "+"
  let cleaned = phoneNumber.replace(/[^\d+]/g, '')

  // Ensure it starts with "+1"
  if (!cleaned.startsWith('+')) {
    cleaned = '+1' + cleaned
  } else if (!cleaned.startsWith('+1')) {
    throw new Error('Invalid country code. Only US numbers (+1) are supported.')
  }

  // Extract the last 10 digits (US number format)
  const match = cleaned.match(/\+1(\d{10})$/)
  if (!match) {
    throw new Error('Invalid phone number format. Must be a valid US number.')
  }

  const formattedNumber = match[1].replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  return `(+1) ${formattedNumber}`
}

export const dateToTimestamp = (date: Date): Timestamp | undefined => {
  if (!date) return
  return Timestamp.fromDate(date)
}

export const bedroomLabel = (no_bedrooms: number | string): string => {
  const bedroomNumber = parseInt(no_bedrooms.toString())
  if (bedroomNumber === 0) {
    return 'Studio'
  } else if (bedroomNumber === 1) {
    return '1 Bedroom'
  } else {
    return `${bedroomNumber} Bedrooms`
  }
}

export const mapApplicationStatusToOwnerView = {
  draft: 'Draft',
  submitted: 'Awaiting Review',
  in_review: 'Awaiting Review',
  withdrawn: 'Withdrawn',
  accepted: 'Offer accepted by landlord',
  rejected: 'Offer rejected by landlord',
  declined: 'Offer declined by applicant',
  confirmed: 'Offer confirmed by applicant',
}

export const listingStatusFormat = {
  open: 'text-green-400 bg-green-50',
  closed: 'text-gray-400 bg-gray-100',
  draft: 'text-yellow-400 bg-yellow-50',
  pending: 'text-red-400 bg-red-50',
}

export const applicationStatusFormat = {
  submitted: 'text-yellow-200',
  in_review: 'text-yellow-400',
  withdrawn: 'text-gray-50',
  accepted: 'text-green-400 border border-dashed border-green-400',
  rejected: 'text-red-400 bg-red-50',
  declined: 'text-red-400 bg-red-50 border-red-400 border',
  confirmed: 'font-bold text-green-400 border bg-green-50 border-green-400',
}

export const numberRegEx = /^[0-9]*$/

export const handleNumberValidation = (e, setValue) => {
  if (e.target.value.match(numberRegEx)) {
    setValue(e.target.value)
  } else {
    alert('Please enter a number')
  }
}

export const unitStatusValue = {
  true: 'Occupied',
  false: 'Vacant',
}

export const unitStatusFormat = {
  true: 'bg-green-50 text-green-400',
  false: 'bg-bluegray-25 text-bluegray-300',
}

export const hasMinWords = (content: string, minWords) => {
  return content && content.trim().split(/[\n\r\s]+/).length >= minWords
}

export const getLocalImageCDNUrl = (imgUrl: string, size = 'lg') => {
  const { assetPrefix, isProd } = publicRuntimeConfig
  const sizes = {
    xs: 50,
    sm: 120,
    md: 480,
    lg: 1080,
    xl: 1920,
  }

  if (typeof imgUrl == 'string' && isProd && size && size in sizes) {
    const urlPrefix = `${assetPrefix}/image/${sizes[size]}`
    const imgFileName = imgUrl.substring(imgUrl.lastIndexOf('/') + 1, imgUrl.lastIndexOf('.'))
    const imgFileExt = imgUrl.substring(imgUrl.lastIndexOf('.') + 1)
    return [`${urlPrefix}/${imgFileName}.webp`, `${urlPrefix}/${imgFileName}.${imgFileExt}`]
  }

  return [imgUrl]
}

export const formatCamelToTitleCase = (key) => {
  return key
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .trim()
}

export const snakeToCamel = (str: string) =>
  str
    ?.toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))

export const getNotNullCurrentFilter = (currentFilter): Partial<ListingFilter> => {
  return Object.fromEntries(
    Object.entries(currentFilter).filter(([_key, ent]) => {
      if (Array.isArray(ent)) {
        return ent.length > 0
      }
      return ent !== null
    })
  )
}

export const formatCount = (number: number): string => {
  return new Intl.NumberFormat().format(number)
}

export const readablePeriod = (start: Date, end: Date): string => {
  const startString = `${dayjs(start).format('MM')}/${dayjs(start).format('YYYY')}`
  const endString = `${dayjs(end).format('MM')}/${dayjs(end).format('YYYY')}`
  return `${startString} - ${endString}`
}

export const numMonths = (start: Date, end: Date): number => {
  return dayjs(end).add(1, 'day').diff(dayjs(start), 'months')
}

export const readableDate = (date: Date): string => {
  return dayjs(date).format('ll')
}

export const toAnalyticsAnnotation = (obj: any): { [key: string]: string | number } => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, val]) => {
      const valType = typeof val
      if (valType !== 'string' && valType !== 'number') {
        return [key, val.toString()]
      }

      return [key, val]
    })
  ) as { [key: string]: string | number }
}

export const hasHistory = () => {
  return document && document.referrer.startsWith(publicRuntimeConfig.SITE_BASE_URL)
}

export const getWindowScrollPercentage = () => {
  if (typeof window === 'undefined') {
    return 0
  }

  if (window && document && document.body) {
    const scrollTop = window.scrollY
    const docHeight = document.body.offsetHeight
    const winHeight = window.innerHeight
    const scrollPercent = scrollTop / (docHeight - winHeight)
    const scrollPercentRounded = Math.round(scrollPercent * 100)

    return scrollPercentRounded
  }

  return 0
}

export const dateToYYYYMMDD = (date: Date) => {
  const formatted = dayjs(date).format('YYYY-MM-DD')
  return formatted
}

export const YYYYMMDDToDate = (date: string) => {
  return dayjs(date).toDate()
}

export const isMobileOrTabletDevice = () => {
  let check = false

  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor)

  return check
}

export const getUnitShareURL = (unit: UnitApi) => {
  return `${publicRuntimeConfig.SITE_BASE_URL}/unit/nyc/${unit.slug}/${unit.addressSlug}`
}

export const getContactShareURL = (contact: Contact) => {
  return `${publicRuntimeConfig.SITE_BASE_URL}/contact/nyc/${contact.id}/${contact.slug}`
}

export const getNewContactShareURL = (contact: ContactApi, city: string) => {
  return `${publicRuntimeConfig.SITE_BASE_URL}/contact/${city}/${contact.id}/${contact.slug}`
}

export const getBuildingShareURL = (building: Partial<Building>) => {
  return `${publicRuntimeConfig.SITE_BASE_URL}/building/${building.productBuilding.city || 'nyc'}/${
    building.id
  }/${building.addressSlug}`
}

export const getNewBuildingShareURL = (building: Partial<BuildingApi>, city: string) => {
  return `${publicRuntimeConfig.SITE_BASE_URL}/building/${city}/${building.slug}/${building.primaryAddress?.slug}`
}

export const getBuildingShareReviewURL = (building: ReviewBuilding) => {
  return `${publicRuntimeConfig.SITE_BASE_URL}/building/${building.cityId || 'nyc'}/${
    building.id
  }/${building.slug}`
}

export const unformatWithCents = (income: any) => {
  const value = income

  // Replace non-numeric characters except dot
  let newValue = value.replace(/^0+/g, '')

  // Replace non-numeric characters except dot
  newValue = newValue.replace(/[^0-9.]/g, '')
  // If there are multiple dots, remove all except the first one
  const dotCount = (newValue.match(/\./g) || []).length
  let newValueWithoutExtraDots = newValue
  if (dotCount > 1) {
    newValueWithoutExtraDots = newValue.replace(/\./g, (match, offset) => (offset ? '' : match))
  }
  // Allow up to two decimal places
  const parts = newValueWithoutExtraDots.split('.')
  if (parts.length > 1) {
    // Take only first two decimal places
    newValueWithoutExtraDots = parts[0] + '.' + parts[1].substring(0, 2)
  }

  return newValueWithoutExtraDots
}

export const formatRentWithCents = (income: any) => {
  if (isNaN(income)) return ''

  return '$' + income
}

export const formatIncome = (income: number, options?: Intl.NumberFormatOptions) => {
  if (isNaN(income)) return ''

  const formatted = Number(income).toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency',
    minimumFractionDigits: 0,
    ...options,
  })

  return formatted
}

export const unformatIncome = (income: string) => {
  const unformatted = income.toString().replace(/,/g, '').replace(/\$/g, '')
  return parseInt(unformatted, 10)
}

export const getNeighborhoodLinkTitle = (data: Neighborhood) => {
  const title =
    data.name +
    ', ' +
    (data?.borough?.name === 'Manhattan' ? 'NYC' : data?.borough?.name) +
    ' - Apartments'
  return title
}

export const ISODateFormat = (str: string | number | Date) => {
  const date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2)
  return [date.getFullYear(), mnth, day].join('-')
}

export const getMaxDate = (data: any, key: string) => {
  return ISODateFormat(
    new Date(
      Math.max(
        ...data.map((x) => {
          return new Date(x[key])
        })
      )
    )
  )
}

export const ISODateFormatMMYYYY = (str: string | number | Date) => {
  const date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2)
  return [mnth, date.getFullYear()].join('-')
}

export const getFirstDateOfMonth = () => {
  const date = new Date()
  return ISODateFormat(new Date(date.getFullYear(), date.getMonth(), 1))
}

export const isNYCReview = (address) => {
  const nycTerms = ['astoria', 'brooklyn', 'manhattan', 'the-bronx', 'new york', 'queens']
  const { addressLine2 } = address ? splitAddress(address) : { addressLine2: '' }
  return nycTerms.some((borough) => addressLine2?.toLowerCase().includes(borough))
}

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const pluralizeString = (string: string, count: number, suffix = 's') =>
  `${count} ${string}${count !== 1 ? suffix : ''}`

export const addDaysToDate = (days: number, date: Date) => {
  const originalDate = new Date(date)
  originalDate.setDate(originalDate.getDate() + days)
  return originalDate
}

export const toKebabCase = (val: string) => {
  const newval = val?.toLowerCase()?.split(' ')?.join('-')
  return newval
}

export function getLogoLetter(val: string) {
  const firstLetter = null

  for (let i = 0; i < val.length; i++) {
    const char = val[i]
    if (/[a-zA-Z]/.test(char)) {
      val = char.toUpperCase()
      break
    }
  }

  if (firstLetter !== null) {
    return firstLetter
  } else {
    return val[0]
  }
}

export const truncateText = (text, wordLimit) => {
  const words = text?.split('')
  if (words?.length > wordLimit) {
    return words.slice(0, wordLimit).join('') + '...'
  }
  return text
}

export const hasBin = (input: string) => {
  if (!input.includes('-')) {
    return false
  } else {
    const parts = input.split('-')
    return parts[0] !== parts[1]
  }
}

export const paymentStatusText = (text: string) => {
  switch (text) {
    case 'settled':
      return 'Paid'
    case 'pending':
      return 'Pending'
    case 'cancelled':
      return 'Failed'
    default:
      return 'Pending'
  }
}

export function isVideoUrl(url) {
  if (!url || typeof url !== 'string') return false

  // List of common video extensions (case-insensitive)
  const videoExtensions = ['mp4', 'webm', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'mpeg', '3gp']
  const extension = url
    .split('?')[0] // Remove query params (e.g., "video.mp4?param=1")
    .split('#')[0] // Remove fragments (e.g., "video.mp4#section")
    .split('.')
    .pop() // Get the last segment after the last dot
    .toLowerCase() // Convert to lowercase for case-insensitive check

  return videoExtensions.includes(extension)
}

export const formatTimeAgo = (date) => {
  const now = moment()
  const givenDate = moment(date)
  const diffDays = now.diff(givenDate, 'days')
  const diffMonths = now.diff(givenDate, 'months')
  const diffYears = now.diff(givenDate, 'years')

  if (diffDays <= 6) {
    return givenDate.fromNow() // "X days ago"
  } else if (diffDays <= 30) {
    return `${Math.floor(diffDays / 7)} week${diffDays >= 14 ? 's' : ''} ago`
  } else if (diffDays <= 365) {
    return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`
  } else {
    return `Over ${diffYears} year${diffYears > 1 ? 's' : ''} ago`
  }
}

export const getOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}
