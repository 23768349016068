import getConfig from 'next/config'
import snakeCase from 'snakecase-keys'
import snakecaseKeys from 'snakecase-keys'

import { apiFetch } from './Api'

const {
  publicRuntimeConfig: { BACKEND_API_LISTING },
} = getConfig()

const baseListingURL = BACKEND_API_LISTING

export async function getListingNeighborhoods(): Promise<any> {
  const data = await apiFetch({
    path: `/neighborhood/`,
    baseURL: baseListingURL,
    noOrigin: true,
  })

  const getNullParentIds = (data) =>
    data.results
      .filter((neighborhood) => neighborhood.parentId === null)
      .map((neighborhood) => neighborhood.id)

  // Function to get the IDs of second-level neighborhoods
  const getSecondLevelIds = (data, nullParentIds) =>
    data.results
      .filter((neighborhood) => nullParentIds.includes(neighborhood.parentId))
      .map((neighborhood) => neighborhood.id)

  // Get the IDs for neighborhoods with null parentId
  const nullParentIds = getNullParentIds(data)

  // Get the IDs for second-level neighborhoods
  const secondLevelIds = getSecondLevelIds(data, nullParentIds)

  // Get the IDs for third-level neighborhoods
  const thirdLevelIds = data.results
    .map((neighborhood) => neighborhood.id)
    .filter((id) => !nullParentIds.includes(id) && !secondLevelIds.includes(id))

  // Sort the data according to the criteria
  const sortedData = [
    ...nullParentIds.map((id) => data.results.find((neighborhood) => neighborhood.id === id)),
    ...secondLevelIds.map((id) => data.results.find((neighborhood) => neighborhood.id === id)),
    ...thirdLevelIds.map((id) => data.results.find((neighborhood) => neighborhood.id === id)),
  ]

  return sortedData
}

export async function getTrendingNeighborhoods(): Promise<any> {
  const data = await apiFetch({
    path: `/neighborhood/trending/`,
    baseURL: baseListingURL,
    noOrigin: true,
  })
  return data.results
}

export async function getListings(
  token: string,
  payload: any,
  page: number,
  page_size?: number
): Promise<any> {
  const data = await apiFetch({
    path: `/unit/filter/?page=${page}&page_size=${page_size ?? 99}`,
    method: 'POST',
    baseURL: baseListingURL,
    noOrigin: true,
    ...(token && { authToken: token }),
    data: snakeCase(payload),
  })
  return data
}

export async function getNewVerifiedListings(
  page: number,
  maxRent?: number,
  params: any = {}
): Promise<any> {
  const data = await apiFetch({
    path: maxRent
      ? `/unit/verified/?page=${page}&page_size=15&max_rent=${maxRent}`
      : `/unit/verified/?page=${page}&page_size=15`,
    params: snakecaseKeys(params),
    baseURL: baseListingURL,
    noOrigin: true,
  })
  return data
}

export async function getNewFurnishedListings(page: number, params: any = {}): Promise<any> {
  const data = await apiFetch({
    path: `/unit/furnished/?page=${page}&page_size=15`,
    params: snakecaseKeys(params),
    baseURL: baseListingURL,
    noOrigin: true,
  })
  return data
}

export async function getNewBuildingListings(
  page: number,
  buildingSlug: string,
  num_bedrooms = ''
): Promise<any> {
  const data = await apiFetch({
    path: `/unit/building/${buildingSlug}/?page=${page}&page_size=6&num_bedrooms=${
      num_bedrooms ?? ''
    }`,
    baseURL: baseListingURL,
    noOrigin: true,
  })
  return data
}

export async function getNewContactListings(
  page: number,
  contactId: string,
  num_bedrooms = ''
): Promise<any> {
  const data = await apiFetch({
    path: `/unit/contact/${contactId}/?page=${page}&page_size=6&num_bedrooms=${num_bedrooms ?? ''}`,
    baseURL: baseListingURL,
    noOrigin: true,
  })
  return data
}

export async function getUnitRetrieve(unitSlug: string): Promise<any> {
  const data = await apiFetch({
    path: `/unit/${unitSlug}/`,
    baseURL: baseListingURL,
    noOrigin: true,
  })
  return data
}

export async function getNewSimilarListings(unitSlug: string): Promise<any> {
  const data = await apiFetch({
    path: `/unit/${unitSlug}/similar/`,
    baseURL: baseListingURL,
    noOrigin: true,
  })
  return data.results
}

export async function getSavedFilters(token: string): Promise<any> {
  const data = await apiFetch({
    path: `/unit/saved_filters/`,
    baseURL: baseListingURL,
    noOrigin: true,
    ...(token && { authToken: token }),
  })
  return data
}

export async function getSavedInterests(token: string): Promise<any> {
  const data = await apiFetch({
    path: `/unit/saved_interests/`,
    baseURL: baseListingURL,
    noOrigin: true,
    ...(token && { authToken: token }),
  })
  return data
}

export async function getFilterCountApi(payload: any, token: string): Promise<any> {
  const data = await apiFetch({
    path: `/unit/filter_count/`,
    method: 'POST',
    baseURL: baseListingURL,
    noOrigin: true,
    ...(token && { authToken: token }),
    data: snakeCase(payload),
  })
  return data
}
